/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Snackbar, Alert } from '@mui/material';

interface CustomSnackbarProps {
  open: boolean;
  autoHideDuration: number;
  onClose: () => any;
  toast: { type: any; message: string };
}

const CustomSnackbar: React.FC<CustomSnackbarProps> = ({ open, autoHideDuration, onClose, toast }) => {
  const getBorderStyle = () => {
    switch (toast.type) {
      case 'success':
        return '6px solid #4CAF50';
      case 'error':
        return '6px solid #FF5733';
      default:
        return 'none';
    }
  };
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
      {/* <Alert onClose={(event) => onClose()} severity={toast.type} sx={{ width: '100%' }}>
        {toast.message}
      </Alert> */}
      <Alert
        onClose={(event) => onClose()}
        severity={toast.type}
        sx={{
          width: '100%',
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          borderLeft: getBorderStyle(),
        }}>
        {toast.message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
