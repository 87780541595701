import * as React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import Home from '../../components/Pages/Home';
import Privacy from '../../components/Pages/Privacy';
import Term from '../../components/Pages/Term';
import Faq from '../../components/Pages/Faq';
import PageNotFound from '../Pages/pageNotFound';

export const Routers = () => {
  return (
    // <Provider store={store}>
    // <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/privacy-policy" element={<Privacy />} />
      <Route path="/term-conditions" element={<Term />} />
      <Route path="/faq" element={<Faq />} />
      {/* <Route path="/welcome" element={<Welcome />} /> */}
      {/* Handle 404 - Not Found*/}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
    // </Router>
    // </Provider>
  );
};
