import React, { useState } from 'react';
import footerLogo from '../../Assets/website/images/footerLogo.svg';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
// import { Link } from 'react-scroll'; //
import Env from '../../services/Env';
import CustomSnackbar from '../../common/Snackbar';
// import { siteUrl } from '../../services/Env';
import '../../Assets/website/css/footer.css';
import {  useNavigate } from 'react-router-dom';
import { scroller } from 'react-scroll'

function AppFooter() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ email: '' });
  const [open, setOpen] = React.useState(false);
  const [toast, setToast] = React.useState({ message: '', type: 'success' });
  const scrollToPricing = () => {
    navigate('/');
    setTimeout(() => {
      scroller.scrollTo('pricing', {
        duration: 500,
        delay: 0,
        smooth: 'true',
      });
    }, 250); // Adjust delay as necessary
  };
 
  const handleClick = () => {
    setOpen(true);
    setTimeout(() => {
      handleClose();
    }, 2 * 1000);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFieldChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const formSubmit = (e: any) => {
    e.preventDefault();
    const dataSubmit = Env.post('invite/inviteuser', formData, '');
    dataSubmit
      .then((res: any) => {
        setToast({ message: res.data.message.value, type: 'success' });
        handleClick();
        setFormData((ps) => ({ ...ps, email: '' }));
      })
      .catch((error: any) => {
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  return (
    <>
      <Container maxWidth="lg" className="topic-container">
        <footer>
          <Box className="footer-box">
            <Grid container spacing={2} sx={{ width: '100%', margin: '0' }}>
              <Grid item xs={12} md={6} sx={{ padding: '0px !important' }}>
                <Box className="footer-info">
                  <a href="/">
                    <img src={footerLogo} />
                  </a>
                  <h3>Subscribe to our newsletter to get the latest news and talent additions.</h3>
                  <form onSubmit={formSubmit}>
                    <Box className="newslatter">
                      <Box className="form-group">
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={12} sm={8} md={7} lg={8}>
                            <TextField
                              sx={{ width: '100%' }}
                              className="form-control"
                              variant="outlined"
                              name="email"
                              type="email"
                              value={formData.email}
                              InputLabelProps={{ shrink: false }}
                              placeholder="Enter email here"
                              onChange={handleFieldChange}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={4} md={5} lg={3} container justifyContent="flex-end">
                            <button type="submit" className="btn" style={{ width: '100%' }}>
                              Submit
                            </button>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </form>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} sx={{ padding: '0px !important' }}>
                <Box className="footer-wrapper">
                  <Grid container spacing={2} sx={{ width: '100%', margin: '0' }}>
                    <Grid item xs={12} sm={6} sx={{ padding: '0px !important' }}>
                      <h4>Quick Links</h4>
                      <ul>
                        {/* <li><a href="#">Plans</a></li> */}

                        <li onClick={scrollToPricing}>
                          {/* <Link to="pricing" spy={true} smooth={true} duration={500}> */}
                          Plans
                          {/* </Link> */}
                        </li>
                        <li>
                          <a href="/term-conditions">Terms & Conditions</a>
                        </li>
                        <li>
                          <a href="/privacy-policy">Privacy Policy</a>
                        </li>
                        <li>
                          <a href="/faq">FAQs</a>
                        </li>
                      </ul>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ padding: '0px !important' }}>
                      <h4>Social</h4>
                      <ul>
                        <li>
                          <a href="https://www.facebook.com/avalblapp/" rel="noreferrer" target="_blank">Facebook</a>
                        </li>
                        <li>
                          <a href="https://www.instagram.com/avalblapp/" rel="noreferrer" target="_blank">Instagram</a>
                        </li>
                        <li>
                          <a href="https://www.linkedin.com/company/avalbl/" rel="noreferrer" target="_blank">LinkedIn</a>
                        </li>
                      </ul>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </footer>
      </Container>
      <Box className="copy-right">
        <h4>Copyright © 2024 by Avalbl. All rights reserved.</h4>
      </Box>
      <CustomSnackbar open={open} autoHideDuration={2000} onClose={handleClose} toast={toast} />
    </>
  );
}

export default AppFooter;
