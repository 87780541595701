import React, { useEffect, useRef, useState } from 'react';
import AppHeader from '../../components/layout/AppHeader';
import AppFooter from '../../components/layout/AppFooter';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import banner from '../../Assets/website/images/banner.svg';
// import banner01 from '../../Assets/website/images/banner-01.svg'; //
import banner02 from '../../Assets/website/images/banner-02.svg';
import banner03 from '../../Assets/website/images/banner-03.svg';
// import banner04 from '../../Assets/website/images/banner-04.svg';//
import check from '../../Assets/website/images/check.svg';
import searchImage from '../../Assets/website/images/searchImage.png';
// import map from '../../Assets/website/images/map.png';
// import mapDots from '../../Assets/website/images/mapDots.png'; //
import mapList from '../../Assets/website/images/list.svg';
// import team from '../../Assets/website/images/team.png';//
// import boy from '../../Assets/website/images/boyImage.svg'; //
import TextField from '@mui/material/TextField';
import { Grid, Typography, styled, Switch, Stack } from '@mui/material';
// import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Element ,scroller } from 'react-scroll';
import Env from '../../services/Env';
import CustomSnackbar from '../../common/Snackbar';
import { siteUrl, siteKey ,imagePath} from '../../services/Env';
import ReCAPTCHA from 'react-google-recaptcha';
import { useLocation } from 'react-router-dom';

// interface PlanPageProps {
//   plan: {
//     label: string;
//     key: string;
//   };
//   type: {
//     label: string;
//     key: string;
//   };
//   amount: string;
// }
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#2CB512',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-thumb:hover': {
    color: 'red',
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const Home = () => {

  document.documentElement.style.setProperty('--cloudfront-image-path', `url(${imagePath}timezone.png)`);
  const recaptcha: any = useRef(null);
  const location = useLocation();
  const [plan, setPlan] = useState(true);
  const [paymentMode, setPaymentMode] = useState<any>({ isMonthly: false, isAnnually: true });

  const [formData, setFormData] = useState({ fullName: '', email: '', message: '' });
  const [open, setOpen] = React.useState(false);
  const [toast, setToast] = React.useState({ message: '', type: 'success' });
  // const [planInfo, setPlanInfo] = React.useState<PlanPageProps[]>([]);
  const [planInfo, setPlanInfo] = React.useState<any>([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (location.hash === '#pricing') {
      scroller.scrollTo('pricing', {
        duration: 500,
        delay: 0,
        smooth: 'true',
      });
    }
  }, [location]);

  function handlePlanChange() {
    setPlan((ps: any) => !ps);
    setPaymentMode((ps: any) => ({ isMonthly: !ps.isMonthly, isAnnually: !ps.isAnnually }));
  }

  useEffect(() => {
    const text = document.querySelector('.text') as HTMLElement;
    if (text) {
      text.innerHTML = text.innerText
        .split('')
        .map((char: any, i: any) => `<span style="transform:rotate(${i * 9.3}deg)">${char}</span>`)
        .join('');
    }

    $('.icon-bb').click(function () {
      const aboutSecOffset = $('#about-sec').offset();

      if (aboutSecOffset) {
        $('html, body').animate(
          {
            scrollTop: aboutSecOffset!.top,
          },
          2000
        );
      }
    });
    getPlanInfo();
  }, []);

  const handleClick = () => {
    setOpen(true);
    setTimeout(() => {
      handleClose();
    }, 2 * 1000);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const basicNavigation = () => {
    window.location.href = `${siteUrl}plan/basic/invite`;
  };

  const premiumNavigation = () => {
    window.location.href = `${siteUrl}plan/premium/email-confirmation?isMonthly=${paymentMode.isMonthly}&isAnnually=${paymentMode.isAnnually}`;
  };

  const formSubmit = (e: any) => {
    e.preventDefault();

    if (recaptcha.current) {
      const captchaValue: any = recaptcha.current.getValue();
      if (!captchaValue) {
        setToast({ message: 'Please verify the reCAPTCHA!', type: 'error' });
        handleClick();
        return;
      } else {
        const dataSubmit = Env.post('contactus/create', formData, '');
        dataSubmit
          .then((res) => {
            setToast({ message: res.data.message.value, type: 'success' });
            handleClick();
            setFormData((ps) => ({ ...ps, fullName: '', message: '', email: '' }));
            recaptcha.current.reset();
          })
          .catch((error) => {
            setToast({ message: error.response.data.message.value, type: 'error' });
            handleClick();
            setFormData((ps) => ({ ...ps, fullName: '', message: '', email: '' }));
            recaptcha.current.reset();
          });
      }
    } else {
      setToast({ message: 'Please verify the reCAPTCHA!', type: 'error' });
      handleClick();
      return;
    }
  };

  const handleFieldChange = (e: any) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const getPlanInfo = () => {
    const getPlan = Env.get(`plan/getplan`, '');
    getPlan
      .then((res) => {
        setPlanInfo(res.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSearch = (e: any) => {
    setSearchValue(e.target.value);
  };

  const handleSearchSubmit = () => {
    const url = `${siteUrl}userFeed?search=${searchValue}`;
    window.open(url, '_blank');
  };

  return (
    <>
      <AppHeader />
      {/* <!-- Start Banner --> */}
      <section className="banner">
        <Container maxWidth="lg" className="topic-container">
          <Box className="banner-info">
            <h1>
              {/* Discovering talent has never been easier*. <span>Introducing AVALBL.</span> */}
              Take control of your talent roster.
            </h1>
            <h3>
              Find the talent you need now! Creative talent organization, booking, tracking, and search services for studios, agencies and creatives. Managing and discovering talent has never been easier.
            </h3>
            <Box className="search-box">
              <ul>
                <li>
                  <button className="btn button" type="button">
                    <i style={{ fontSize: '29px' }} className="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
                </li>
                <li>
                  <a href="#" style={{ cursor: 'default', pointerEvents: 'none', textDecoration: 'none' }}>
                    I&apos;m looking for a
                  </a>
                </li>
                <li>
                  <img src={banner} alt="banner" />
                </li>
                <li>
                  <a href="#" style={{ cursor: 'default', pointerEvents: 'none', textDecoration: 'none' }}>
                    graphic designer
                  </a>
                </li>
                <li>
                  <a href="#" style={{ cursor: 'default', pointerEvents: 'none', textDecoration: 'none' }} className="small">
                    specializing
                  </a>
                </li>
                <li>
                  {/* <img src= "https://d1b0o98gwlv9wy.cloudfront.net/marketingImage/banner-01.svg" alt="banner" /> */}
                  <img src= {`${imagePath}banner-01.svg`} alt="banner" />
                </li>
                <li>
                  <a href="#" style={{ cursor: 'default', pointerEvents: 'none', textDecoration: 'none' }} className="small">
                    in minimal design
                  </a>
                </li>
                <li>
                  <img src={banner02} alt="banner" />
                </li>
                <li>
                  <a href="#" style={{ cursor: 'default', pointerEvents: 'none', textDecoration: 'none' }} className="small">
                    using photoshop
                  </a>
                </li>
                <li>
                  <img src={banner03} alt="banner" />
                </li>
                <li>
                  <img src={`${imagePath}banner-04.svg`} alt="banner" />
                </li>
              </ul>
              <form>
                <Box className="form-group">
                  {/* <i className="fa fa-search" aria-hidden="true"></i>searchImage */}
                  <i>
                    <img className="fa fa-search" src={searchImage} alt="searchImage" />
                  </i>
                  <TextField
                    id="text-example"
                    fullWidth
                    className="form-control"
                    variant="outlined"
                    InputLabelProps={{ shrink: false }}
                    value={searchValue}
                    onChange={handleSearch}
                    placeholder="Search Talent"
                    // InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //       <img src={searchImage} alt="searchImage" />
                    //     </InputAdornment>
                    //   ),
                    // }}
                  />
                  <button type="submit" className="btn search-btn" onClick={handleSearchSubmit}>
                    Search
                  </button>
                </Box>
              </form>
            </Box>

            <div className="circle">
              <div className="icon-bb"></div>
              <div className="text">
                <p>SCROLL DOWN- SCROLL DOWN- SCROLL DOWN-</p>
              </div>
            </div>
          </Box>
          {/* <RotatingText/>       */}
          {/* </Box> */}
        </Container>
      </section>
      {/* <!-- End Banner --> */}
      {/* <!-- Start About us Section --> */}
      <section className="about" id="about-sec">
        <Box className="container">
          <Box className="about-info">
            <h3>ABOUT AVALBL</h3>
            <h2>Talent management on easy mode.</h2>
            <p>
              Managing your talent roster was a challenge. Keeping track of everyone&apos;s availability and ensuring you
              could secure the right people for your project was a gamble. Avalbl is a one stop solution to manage your
              talent roster and discover new talent along the way. With Avalbl you can:
            </p>
            <ul>
              <li>Track talent availability via a calendar</li>
              <li>
                Discover and search via powerful search <span style={{ opacity: 0.9 }}>tools</span>
              </li>
              <li style={{ opacity: 0.8 }}>
                Book talent, request <span style={{ opacity: 0.8 }}> and manage holds</span>
              </li>
              <li style={{ opacity: 0.7 }}>Organize talent and share with other members</li>
              <li style={{ opacity: 0.5 }}>Communicate with talent via built-in messaging</li>
            </ul>
          </Box>
          <Box className="time-zone-box">
            <h3>SEARCH, BOOK, MANAGE AND SHARE.</h3>
            <h2>Build and organize a better roster.</h2>
            <h4>
              Track and find new talent so you&apos;re never left wondering if they have the skills and availability to get
              your project done. Sign Up and you&apos;ll be on your way to tracking and organizing your roster and finding
              people with the specific skills or details you want immediately.
            </h4>
            <Grid sx={{backgroundImage:`url(${imagePath}mapDots.png)`, width:'100%', height:'100%'}}>
              {/* <Grid sx={{paddingTop:'30px'}}>
                <Grid container sx={{marginLeft:'100px'}}>
                  <Grid item><img src={mapList} alt="map" style={{marginBottom:'0px'}} /></Grid>
                  <Grid item className='map-list' sx={{paddingTop:'9px'}}>Create a premium account to get started and complete your profile.</Grid>
                </Grid>
                <Grid container sx={{marginLeft:'100px'}}>
                  <Grid item><img src={mapList} alt="map" style={{marginBottom:'0px'}}  /></Grid>
                  <Grid item className='map-list' sx={{paddingTop:'9px'}}>Once completed, head over to the My Roster page and click on the Invite button.</Grid>
                </Grid>
                <Grid container sx={{marginLeft:'100px'}}>
                  <Grid item><img src={mapList} alt="map" style={{marginBottom:'0px'}}  /></Grid>
                  <Grid item className='map-list' sx={{paddingTop:'9px'}}>Enter in the email address of each talent member you’d like to invite and press send.</Grid>
                </Grid>
                <Grid container sx={{marginLeft:'100px'}}>
                  <Grid item><img src={mapList} alt="map" style={{marginBottom:'0px'}}  /></Grid>
                  <Grid item className='map-list' sx={{paddingTop:'9px'}}>Once a user accepts the invite and makes a profile, they’ll appear on your My Roster page.</Grid>
                </Grid>
                <Grid container sx={{marginLeft:'100px'}}>
                  <Grid item><img src={mapList} alt="map" style={{marginBottom:'30px'}}  /></Grid>
                  <Grid item className='map-list' sx={{paddingTop:'9px'}}>Organize users into custom lists, search, message and send booking requests.</Grid>
                </Grid>
              </Grid> */}

              <Grid sx={{ paddingTop: '30px' }}>
                <ul style={{ marginLeft: '100px', listStyleType: 'none', padding: 0 }} className='buildOrg'>
                  <li style={{ display: 'flex', alignItems: 'center',  }}>
                    <img src={mapList} alt="map" style={{ marginBottom: '0px' }} />
                    <div className='map-list' style={{ paddingTop: '9px', marginLeft: '8px' }}>Create a premium account to get started and complete your profile.</div>
                  </li>
                  <li style={{ display: 'flex', alignItems: 'center',  }}>
                    <img src={mapList} alt="map" style={{ marginBottom: '0px' }} />
                    <div className='map-list' style={{ paddingTop: '9px', marginLeft: '8px' }}>Once completed, head over to the My Roster page and click on the Invite button.</div>
                  </li>
                  <li style={{ display: 'flex', alignItems: 'center',  }}>
                    <img src={mapList} alt="map" style={{ marginBottom: '0px' }} />
                    <div className='map-list' style={{ paddingTop: '9px', marginLeft: '8px' }}>Enter in the email address of each talent member you’d like to invite and press send.</div>
                  </li>
                  <li style={{ display: 'flex', alignItems: 'center', }}>
                    <img src={mapList} alt="map" style={{ marginBottom: '0px' }} />
                    <div className='map-list' style={{ paddingTop: '9px', marginLeft: '8px' }}>Once a user accepts the invite and makes a profile, they’ll appear on your My Roster page.</div>
                  </li>
                  <li style={{ display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
                    <img src={mapList} alt="map" style={{ marginBottom: '0px' }} />
                    <div className='map-list' style={{ paddingTop: '9px', marginLeft: '8px' }}>Organize users into custom lists, search, message and send booking requests.</div>
                  </li>
                </ul>
              </Grid>


              <Grid>
                <p className='map-list'>Click below to get started with a 30 day free trial</p>
                <button
                    onClick={premiumNavigation}
                    className="btn btn-border-white freeTrails"
                    style={{ fontFamily: '"Onset", sans-serif !important', fontSize: '15px',
                      height: '56px', minWidth: '320px' }}>
                    Start your 30 day Free Trial
                </button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </section>
      {/* <!-- End About us Section --> */}
      {/* <!-- start Testimonial Sections --> */}
      {/* <section className="testimonial">
        <Box className="container">
          <Box className="title-testimonial">
            <h3 className="green-text">Testimonials</h3>
            <h4 className="title-one">What people are saying about Avalbl.</h4>
          </Box>
          <OwlCarousel
            className="owl-theme"
            loop
            margin={10}
            nav
            items={1}
            mouseDrag={false}
            responsive={{ '0': { items: 1, dots: false } }}>
            <Box className="item">
              <Box sx={{ paddingTop: '4rem' }}>
                <Grid container spacing={2} sx={{ width: '100%', margin: '0' }} className="mob-view">
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{ paddingLeft: '0px !important', paddingRight: '16px' }}
                    className="mob-view-left">
                    <Box className="testimonial-info">
                      <h4>
                        &quot;Keeping track of who was available in my roster was nearly impossible, not anymore.&quot;
                      </h4>
                      <p style={{ fontWeight: 300, lineHeight: '34px' }}>
                        &quot;Avalbl now saves me a tremendous amount of work. I would spend days simply tracking people down. Now I can see who’s available, place a hold or book them instantly.&quot;
                      </p>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} className="mob-view-right">
                    <Box className="testimonial-box">
                      <img src={`${imagePath}boyImage.svg`} alt="demo" />
                      <Box className="name-test">
                        <h3>Kris Rivel</h3>
                        <p>Creative Director</p>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </OwlCarousel>
        </Box>
      </section> */}
      {/* <!-- End Testimonial Sections --> */}
      {/* <!-- Start Team Sections --> */}
      <section className="team">
        <Box className="container">
          <h3 className="green-text">The ultimate talent database</h3>
          <h4 className="title-one">A collective of the world&apos;s best, in one place.</h4>
          <Typography className="title-text">
            Vetted, quality talent from the start. Avalbl&apos;s roster is built by combining your talent roster with others.
            The result is a global database of the talent with the most desired skills and qualities.
          </Typography>
        </Box>
        <img src={`${imagePath}team.png`} alt="team" />
      </section>
      {/* <!-- End Team Sections --> */}
      {/* <!-- Start Pricing Sections --> */}
      <Element name="pricing">
        <section className="prices">
          <Box className="container">
            <h3 className="green-text">Pricing</h3>
            <h4 className="title-one">Get started today</h4>
            <p className="title-text">Build your roster and start searching today or get yourself listed.</p>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center" sx={{ mt: 1 }}>
              <Typography
                sx={{
                  fontWeight: '600 !important',
                  color: !plan ? '#001F1A !important' : '',
                  fontSize: '18px',
                  lineHeight: '28px',
                  paragraph: '18px',
                  fontFamily: "'Onest', sans-serif !important",
                }}>
                Monthly
              </Typography>
              <AntSwitch inputProps={{ 'aria-label': 'ant design' }} checked={plan} onChange={handlePlanChange} />
              <Typography
                sx={{
                  fontWeight: '600 !important',
                  color: plan ? '#001F1A !important' : '',
                  fontSize: '18px',
                  lineHeight: '28px',
                  paragraph: '18px',
                  fontFamily: "'Onest', sans-serif !important",
                }}>
                Yearly
              </Typography>
              <Typography
                sx={{
                  backgroundColor: '#FFFAEB',
                  borderRadius: '16px',
                  color: '#B54708',
                  fontWeight: 500,
                  fontSize: '12px',
                  width: '75px',
                  textDecoration: plan ? 'none' : 'line-through',
                  textAlign: 'center',
                  fontFamily: "'Onest', sans-serif !important",
                }}
                component={'div'}>
                {/* SAVE {state.plan[1]?.discount} */}
                SAVE {planInfo[1]?.discount}
              </Typography>
            </Stack>
            <Grid container spacing={2} sx={{ width: '100%', margin: '0' }} className="row plan">
              <Grid item xs={12} md={6} className="plan-left" sx={{ mt: '24px' }}>
                <Box className="plan-box">
                  {/* <h3>{planInfo && planInfo?.length > 0 && <b>{planInfo[0].plan && planInfo[0].plan.label}</b>}</h3> */}
                  <h3>{planInfo && planInfo?.length > 0 && <b>{planInfo[0].plan && planInfo[0].plan.label}</b>}</h3>
                  <p>Get listed and get hired</p>
                  <h4>
                    {/* <b>Free</b> */}
                    {planInfo && planInfo?.length > 0 && <b>{planInfo[0].type && planInfo[0].type.label}</b>}
                  </h4>
                  <p>Invitation code required</p>
                  <button onClick={basicNavigation} className="btn btn-border">
                    Get Started
                  </button>
                  <ul
                    style={{
                      marginLeft: '23px',
                      fontSize: '16px',
                      fontWeight: 400,
                      color: '#667085',
                    }}>
                    <li>
                      <span
                        style={{
                          position: 'absolute',
                          left: '0',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          width: '19px',
                          height: '24px',
                          background: `url(${check}) no-repeat center`,
                          backgroundSize: 'cover',
                        }}
                      />
                      Detailed, searchable profile
                    </li>
                    <li>
                      <span
                        style={{
                          position: 'absolute',
                          left: '0',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          width: '19px',
                          height: '24px',
                          background: `url(${check}) no-repeat center`,
                          backgroundSize: 'cover',
                        }}
                      />
                      Availability management
                    </li>
                    <li>
                      <span
                        style={{
                          position: 'absolute',
                          left: '0',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          width: '19px',
                          height: '24px',
                          background: `url(${check}) no-repeat center`,
                          backgroundSize: 'cover',
                        }}
                      />
                      Calendar system
                    </li>
                    <li>
                      <span
                        style={{
                          position: 'absolute',
                          left: '0',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          width: '19px',
                          height: '24px',
                          background: `url(${check}) no-repeat center`,
                          backgroundSize: 'cover',
                        }}
                      />
                      Receive and message premium users
                    </li>
                    <li>
                      <span
                        style={{
                          position: 'absolute',
                          left: '0',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          width: '19px',
                          height: '24px',
                          background: `url(${check}) no-repeat center`,
                          backgroundSize: 'cover',
                        }}
                      />
                      Receive and accept project requests
                    </li>
                  </ul>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                className="plan-right"
                sx={{ fontFamily: '"Onset", sans-serif !important', mt: '24px' }}>
                <Box className="plan-box" sx={{ fontFamily: '"Onset", sans-serif !important' }}>
                  <h3>
                    <b>Premium</b>
                  </h3>
                  {plan && planInfo[1]?.amount?.savedAmount ? (
                  <p>You will save <span style={{ color: 'black' }}>{planInfo[1].amount.savedAmount}</span></p>
                ) : null}
                  <h4 style={{ fontFamily: "'Onest', sans-serif !important",  marginTop: plan && plan ? '16px' : '47px',}}>
                    {!plan ? planInfo[1]?.amount?.monthly : planInfo[1]?.amount?.yearly}<span style={{ fontSize: '20px' }}>/mon</span>
                  </h4>
                  {plan && planInfo[1]?.amount?.totalAmount ? (
                  <p>
                    <span style={{ color: 'black' }}>{planInfo[1].amount.totalAmount}</span>, billed annually
                  </p>
                ) : null}
                  <button
                    onClick={premiumNavigation}
                    className="btn btn-border-white"
                    style={{ fontFamily: '"Onset", sans-serif !important', marginTop: plan && plan ? '0px' : '37px', }}>
                    Start 30-day Free Trial
                  </button>
                  <ul
                    style={{
                      marginLeft: '23px',
                      fontSize: '16px',
                      fontWeight: 400,
                      color: '#667085',
                    }}>
                    <li>
                      <span
                        style={{
                          position: 'absolute',
                          left: '0',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          width: '19px',
                          height: '24px',
                          background: `url(${check}) no-repeat center`,
                          backgroundSize: 'cover',
                        }}
                      />
                      All features of Basic profile plus:
                    </li>
                    <li>
                      <span
                        style={{
                          position: 'absolute',
                          left: '0',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          width: '19px',
                          height: '24px',
                          background: `url(${check}) no-repeat center`,
                          backgroundSize: 'cover',
                        }}
                      />
                      Advanced talent search tools
                    </li>
                    <li>
                      <span
                        style={{
                          position: 'absolute',
                          left: '0',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          width: '19px',
                          height: '24px',
                          background: `url(${check}) no-repeat center`,
                          backgroundSize: 'cover',
                        }}
                      />
                      Roster management and organization
                    </li>
                    <li>
                      <span
                        style={{
                          position: 'absolute',
                          left: '0',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          width: '19px',
                          height: '24px',
                          background: `url(${check}) no-repeat center`,
                          backgroundSize: 'cover',
                        }}
                      />
                      Add and message Basic members
                    </li>
                    <li>
                      <span
                        style={{
                          position: 'absolute',
                          left: '0',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          width: '19px',
                          height: '24px',
                          background: `url(${check}) no-repeat center`,
                          backgroundSize: 'cover',
                        }}
                      />
                      Send and manage booking requests
                    </li>
                  </ul>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </section>
      </Element>
      {/* <!-- End Pricing Sections --> */}
      {/* <!-- Start Team Form Sections --> */}
      <section className="efforts" id="contact-us">
        <Box className="container">
          <Grid container spacing={2} sx={{ width: '100%', margin: '0', alignItems: 'center' }}>
            <Grid item xs={12} md={6} sx={{ padding: '0px !important' }}>
              <h3 className="green-text">Contact us</h3>
              <h4 className="title-one">We want to hear from you!</h4>
              <p className="title-text">Do you have feedback, suggestions or want to know</p>
              <p className="title-text-1">more about Avalbl and how it works? Reach out to us!</p>
            </Grid>
            <Grid item xs={12} md={6} sx={{ padding: '0px !important' }}>
              <Box className="form-box">
                <h4>We&apos;d love to hear from you!</h4>
                <form onSubmit={formSubmit}>
                  <Box className="form-group">
                    <label>Full Name</label>
                    <TextField
                      fullWidth
                      className="form-control"
                      id="effort-input"
                      name="fullName"
                      variant="outlined"
                      value={formData.fullName}
                      InputLabelProps={{ shrink: false }}
                      placeholder="Enter name here"
                      onChange={handleFieldChange}
                      required
                    />
                  </Box>
                  <Box className="form-group">
                    <label>Email</label>
                    <TextField
                      fullWidth
                      name="email"
                      id="effort-inputs"
                      className="form-control"
                      value={formData.email}
                      type="email"
                      variant="outlined"
                      InputLabelProps={{ shrink: false }}
                      placeholder="Enter email here"
                      onChange={handleFieldChange}
                      required
                    />
                  </Box>
                  <Box className="form-group">
                    <label>Message(Optional)</label>
                    <textarea
                      style={{ fontFamily: '"Onset", sans-serif !important' }}
                      className="form-control"
                      name="message"
                      value={formData.message}
                      placeholder="Type your message here..."
                      onChange={handleFieldChange}></textarea>
                  </Box>
                  <ReCAPTCHA sitekey={siteKey} ref={recaptcha} type="image" />
                  <button style={{ marginTop: '20px' }} type="submit" className="btn">
                    Submit
                  </button>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </section>
      {/* <!-- End Team Form Sections --> */}
      <AppFooter />
      <CustomSnackbar open={open} autoHideDuration={2000} onClose={handleClose} toast={toast} />
    </>
  );
};

export default Home;