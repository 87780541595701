/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import add from '../../Assets/website/images/add.svg';
import AppHeader from '../../components/layout/AppHeader';
import AppFooter from '../../components/layout/AppFooter';

function Faq() {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <AppHeader />
      <section className="privacy faq-page">
        <Box className="container">
          <Box className="privacy-box">
            <h3>Answers to some burning questions you may have about Avalbl</h3>
            <Typography className="sub-text">
              Below are hopefully answers to questions you may have about Avalbl's current status, development and its future. If you don't see an answer below, please contact us and we'll get you the answer you're looking for.
            </Typography>
          </Box>
          <Box className="accord-list policy-list">
            <Box className="policy-item">
              <ul>
                <li>
                  <Accordion className="expander" expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                      expandIcon={<img src={add} className="add" />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header">
                      <Typography sx={{ width: '100%', flexShrink: 0 }}>
                        <Box className="accordian-head">What is Avalbl?</Box>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className="accordianAnswer">
                        Avalbl is an effort to organize and streamline the virtually impossible task of tracking talent in the post-production industry. Our vision is to create a shared structure and deep network of verified artists which will ultimately compile the most accurate and updated list of the top talent in the post-visual effects, CG and computer graphics industry. It's intended to provide producers, directors, creative directors, agencies and anyone else that wants to cut through the noise and find what they really want, the TOP talent in the business.​
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </li>
                <li>
                  <Accordion className="expander" expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                      expandIcon={<img src={add} className="add" />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header">
                      <Typography sx={{ width: '100%', flexShrink: 0 }}>
                        <Box className="accordian-head">Who is behind Avalbl?</Box>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className="accordianAnswer">
                        <a href="https://www.linkedin.com/in/krisrivel/" target="_blank" rel="noreferrer">Kris Rivel</a>, creative director of motion media and animation at <a href="https://www.aliceblue.com/" target="_blank" rel="noreferrer">Aliceblue</a> is the creator and CEO of Avalbl. For over twenty years, Kris has been working in the CG/visual effects industry and during that time, he's often searching for new talent or sending inquiries to those in his private roster to see if they're available for a project. Many years ago, he had the idea for a live site that would track talent accurately and eliminate the need for constant searching, followed by the waiting game to hear back from those he did find, often unsure if they were available or even had the skills he was looking for. He's also supervised many post-production projects and personally negotiated talent contracts and schedules, managing many teams of artists to ensure the right personnel were assigned to the right projects.​
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </li>
                <li>
                  <Accordion className="expander" expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary
                      expandIcon={<img src={add} className="add" />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header">
                      <Typography sx={{ width: '100%', flexShrink: 0 }}>
                        <Box className="accordian-head">How do artists or users get listed on Avalbl?</Box>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className="accordianAnswer">
                        Premium users who want to upload their roster or search the vetted users already on Avalbl can <a href="https://app.avalbl.com/">make an account</a> at any time. If you are an artist or user with talent, looking to get hired for projects, you must be invited to Avalbl by a premium user or by the Avalbl team.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </li>
                <li>
                  <Accordion className="expander" expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary
                      expandIcon={<img src={add} className="add" />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header">
                      <Typography sx={{ width: '100%', flexShrink: 0 }}>
                        <Box className="accordian-head">What makes Avalbl unique over job postings and other similar services?</Box>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className="accordianAnswer">
                        Recruiters that are very keen on exactly what sort of candidate they are looking for may have difficulty finding the right candidate. Their in-house roster list is often outdated and inaccurate. Avalbl assists recruiters by keeping talent on top of their schedule, updating their portfolio, contact information, bio and portfolio. It’s like having your own talent management team at your fingertips 24 hours a day.
                        When using a job posting; the process of reviewing many applicants is exhausting and wasteful in terms of time and resources. Job searches also generally do not reach the very best talent out there and are mainly seen and responded to by the artists who are either looking for work or lack the experience and skills needed to stay booked. Avalbl helps by giving recruiters the ability to better connect with the top talent that has the specific skills, criteria, location and other details desired.
                        Other network services still rely on users to manually update their profiles and availability status or simply do not disclose availability or other details. They also may not provide any assistance in helping you figure out who you need or what skills you should look for. Avalbl goes beyond what other providers can show you. Maybe you need a generalist or a specialist. Avalbl will ensure you're connected to the right people with the right skills.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </li>
                <li>
                  <Accordion className="expander" expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                    <AccordionSummary
                      expandIcon={<img src={add} className="add" />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header">
                      <Typography sx={{ width: '100%', flexShrink: 0 }}>
                        <Box className="accordian-head">How does Avalbl help the artists?</Box>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className="accordianAnswer">
                        Avalbl is an efficient platform for connecting artists and talent seekers. A frustrating aspect for many artists when connecting to a client is simply the client being unaware fully of what they need in terms of skills, availability and budget. Talent seekers on Avalbl have sorted through the listing and/or had a consultation with our team so as an artist listed on Avalbl, when a client reaches out to you, you can be assured you are who they are looking for and they are ready to go!
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </li>
                <li>
                  <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                    <AccordionSummary
                      expandIcon={<img src={add} className="add" />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header">
                      <Typography sx={{ width: '100%', flexShrink: 0 }}>
                        <Box className="accordian-head">Is Avalbl only for those in creative fields?</Box>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className="accordianAnswer">
                        Avalbl is currently only serving the visual effects, animation and computer graphics industry but eventually will expand into other creative categories and then into other sectors.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </li>
                <li>
                  <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                    <AccordionSummary
                      expandIcon={<img src={add} className="add" />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header">
                      <Typography sx={{ width: '100%', flexShrink: 0 }}>
                        <Box className="accordian-head">Does Avalbl cost anything?</Box>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className="accordianAnswer">
                        To be listed on Avalbl as a user where you can manage your profile, schedule, get hired and discuss projects with premium users is FREE. If you’d like to search, reach out to talent, hire users and manage a roster, the current subscription price is $45/month or $40.50/month if you subscribe for a whole year. Prices may change as new features are added and enhanced.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </li>
                <li>
                  <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                    <AccordionSummary
                      expandIcon={<img src={add} className="add" />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header">
                      <Typography sx={{ width: '100%', flexShrink: 0 }}>
                        <Box className="accordian-head">Is Avalbl only in the U.S.?</Box>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className="accordianAnswer">
                        Anyone in the world is welcome to join Avalbl!
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </li>
                <li>
                  <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                    <AccordionSummary
                      expandIcon={<img src={add} className="add" />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header">
                      <Typography sx={{ width: '100%', flexShrink: 0 }}>
                        <Box className="accordian-head">What sort of features are in store for future releases?</Box>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className="accordianAnswer">
                        As much as we'd love to share all of our plans, we want to remain nimble and focus on our core purpose at this time. However, we have an arsenal of surprises, features and plans in store for Avalbl that will truly set it apart from competing tools and methods so definitely stay tuned!
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </li>
                <li>
                  <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                    <AccordionSummary
                      expandIcon={<img src={add} className="add" />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header">
                      <Typography sx={{ width: '100%', flexShrink: 0 }}>
                        <Box className="accordian-head">What if my skills or tools aren’t listed as options in my profile?</Box>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className="accordianAnswer">
                        If you don’t see a particular skill or tool listed, definitely let us know on our contact form! We are eager to include all relevant skills and tools typically used in the creative and post production industry so we value your feedback.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </li>
                <li>
                  <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
                    <AccordionSummary
                      expandIcon={<img src={add} className="add" />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header">
                      <Typography sx={{ width: '100%', flexShrink: 0 }}>
                        <Box className="accordian-head">Where can I report bugs or provide feedback?</Box>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className="accordianAnswer">
                        Avalbl will improve with added features over time but we also need your feedback which is critical as it helps resolve bugs, increase performance and provide a better experience for both the talent members listed in the roster and the recruiters so definitely visit our contact form and let us know.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </li>
              </ul>
            </Box>
          </Box>
        </Box>
      </section>
      <AppFooter />
    </>
  );
}

export default Faq;



