import React from 'react';
// common css import start
import '../../Assets/website/css/style.css';
import '../../Assets/website/css/homepage.css';
import '../../Assets/website/css/faq.css';
import '../../Assets/website/css/common.css';
import '../../Assets/website/css/responsive.css';
// common css import end

import logo from '../../Assets/website/images/logo.svg';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { siteUrl } from '../../services/Env';

function AppHeader() {
  return (
    <>
      <header className="header-2">
        <Grid container spacing={2} sx={{ width: '100%', margin: '0', alignItems: 'center' }}>
          <Grid item xs={5} sm={6} sx={{ padding: '0px !important' }}>
            <Box className="logo">
              <a href="/">
                <img src={logo} />
              </a>
            </Box>
          </Grid>
          <Grid item xs={7} sm={6} sx={{ padding: '0px !important' }}>
            <Box className="login-btn">
              <a href={`${siteUrl}plan`} className="btn btn-border">
                Sign Up
              </a>
              <a href={`${siteUrl}login`} className="btn">
                Sign In
              </a>
            </Box>
          </Grid>
        </Grid>
      </header>
    </>
  );
}

export default AppHeader;
